<template>
  <div>

    <Head :navId="4"></Head>
    <aside id="fh5co-hero">
      <div class="flexslider">
        <ul class="slides">
          <li :style="'background-image: url('+(bannerSwitch?banner:banner1)+');'"></li>
        </ul>
      </div>
    </aside>

    <div class="container product-nav">
      <span @click="pagesTo('/home')" style="cursor: pointer;">首页</span>
      <span>>></span>
      <span @click="pagesTo('/Skill')" style="cursor: pointer;">新闻资讯</span>
      <span>>></span>
      <span style="color: #1D5EED;">{{detail.title}}</span>
    </div>

    <div class="container">
      <div class="product-top">
        <div class="product-title">{{detail.title}}</div>

        <div class="product-title2">
          <span>来源:本站</span>
          <span v-if="detail.author">作者:{{detail.author}}</span>
          <span>时间：{{timestampToString(detail.add_time)}}</span>
        </div>

        <!-- <img class="product-top-img" :src="detail.image_input" alt /> -->

        <!-- <el-button
              style="background-color: #1D5EED;"
              type="primary"
              icon="el-icon-chat-dot-square"
            >在线咨询</el-button>
            <el-button
              style="background-color: #1D5EED;"
              type="primary"
              icon="el-icon-phone-outline"
        >010-59396550</el-button>-->
      </div>
      <!-- </div> -->
      <!-- </div> -->

      <!-- <div class="product-line">产品详情</div> -->

      <div class="container">
        <div class="detail-content">
          <div v-html="detail.content"></div>

        </div>
      </div>
      <el-divider></el-divider>

      <div v-if="this.content.length" class="news-detail-bottom">
        <span v-if="num==0">当前为第一篇内容！</span>
        <span class="right" @click="toDetail(content[num-1])" v-else>上一篇：{{ content[num-1].title }}</span>
        <span v-if="num==this.content.length-1">当前为最后一篇内容！</span>
        <span v-else class="right" @click="toDetail(content[num+1])">下一篇：{{ content[num+1].title }}</span>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { Head, Footer } from "../components/unit/index";
import { newsDetail, getNewsList } from "../api/index";
import { getBanner } from "@/api/home";

// import { Delete, Edit, Search, Share, Upload } from '@element-plus/icons-vue'
export default {
  components: {
    Head,
    Footer,
    // Delete, Edit, Search, Share, Upload
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
  },
  data() {
    return {
      schemeList: [],
      navIndex: 0,
      num: 0,
      therapy: require("../static/images/newsImg.png"),
      navImg: require("../static/index/nav-i.png"),
      navImg1: require("../static/index/nav-i1.png"),
      detail: {},
      title: "",
      typeId: "",
      content: [],
      banner: '',
      banner1: ""
    };
  },
  computed: {
    bannerSwitch() {
      return this.$store.state.bannerSwitch
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.title = to.query.title;
    this.typeId = to.query.typeId;
    next();
    this.onShow();
  },
  created() { },
  mounted() {
    this.onShow();
  },
  methods: {
    toDetail(item) {
      // const activeName = this.activeName;
      this.$router.push({
        path: "/Newsdetails",
        query: {
          id: item.id,
          title: this.title,
          typeId: this.typeId,
        },
      });
    },
    pagesTo(page) {
      this.$router.push({
        path: page,
      });
    },
    onShow() {
      getBanner(4).then((res) => {
        this.banner = res.data[0].img_path;
        this.banner1 = res.data[0].mobile_img_path;
      });
      newsDetail({ id: this.$route.query.id }).then((res) => {
        this.detail = res.data;
        this.title = this.detail.type_name;
        this.typeId = this.detail.cid;
        let pagekeywords = document.querySelector('meta[name="keywords"]');
        pagekeywords.setAttribute("content", this.detail.keyword);

        let pagetitle = document.querySelector('meta[name="title"]');
        pagetitle.setAttribute("content", this.detail.title);

        let description = document.querySelector('meta[name="description"]');
        description.setAttribute("content", this.detail.seo_title);
        document.getElementsByTagName("title")[0].innerText = this.detail.title

        this.newsList();
      });
    },
    newsList() {
      let data = {
        type_id: this.typeId,
        page_index: 1,
        page_size: 1000,
      };
      getNewsList(data).then((res) => {
        this.num = 0;
        res.data.data.forEach((item, i) => {
          if (item.id == this.detail.id) this.num = i;
        });
        this.content = res.data.data;
      });
    },
    timestampToString(timestamp) {
      timestamp = Number(timestamp * 1000);
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      const dateString = `${year}-${month}-${day}`;
      const timeString = `${hours}:${minutes}:${seconds}`;
      // ${timeString}
      return `${dateString}`;
    },
    getWorks() {
      let params = {
        cates: 25,
      };
      getWorksListApi(params).then((res) => {
        if (res.code == 200) {
          let data = res.data;
          let arr = [];
          data.forEach(function (item) {
            let img = item.image;
            // let val = img
            arr.push(img);
          });
          this.schemeList = arr;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
// .product-nav {
//   width: 300px !important;
// }
@media screen and (max-width: 768px) {
  .card-div {
    flex-direction: column;
    margin: 10px 10px 0 !important;
  }

  .product-nav-div {
    width: 150px !important;
    font-size: 18px !important;
  }
  .card-div .img-div {
    width: 100% !important;
    img {
      width: 100% !important;
    }
  }
  .text-div {
    // font-size: 20px !important;
    padding-bottom: 40px !important;
  }
  .radius-div {
    font-size: 16px !important;
    right: 20px;
  }
  .is-background {
    margin-top: 20px;
  }

  .product-top-img {
    width: 350px !important;
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  //line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  //background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  //background-color: #d3dce6;
}
.check-more {
  display: block;
  width: 80px;
  height: 25px;
  border: 2px solid #999;
  text-align: center;
  line-height: 25px;
  position: absolute;
  bottom: 50px;
}
.word-p {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.course-img {
  img {
    width: 100%;
    height: 100%;
  }
}

.img_Soft {
  width: 40%;
  height: 400px;
  img {
    width: 100%;
    height: 100%;
  }
}
.container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  .container-item {
    width: 30%;
    // margin-bottom: 20px;
    margin: 0 1.666% 30px;
    cursor: pointer;
    box-shadow: 0px 0px 10px 1px rgba(29, 51, 100, 0.1);
    img {
      width: 100%;
    }
    div {
      text-align: center;
      padding: 10px 0;
      color: #333333;
      font-size: 12px;
      font-size: 100%;
    }
  }
}

.row {
  display: flex;
}

::v-deep .my-gallery {
  //deep深层作用选择器
  display: flex;
  height: 390px;
  figure {
    width: 30%;
    margin: 5px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (min-width: 390px) and (max-width: 969px) {
  ::v-deep .my-gallery {
    //deep深层作用选择器
    display: flex;
    flex-direction: column;
    height: 100%;
    figure {
      width: 100%;
      margin: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

::v-deep .pswp__button--share {
  display: none !important;
}
.product-nav {
  margin-bottom: 20px;
  span {
    margin-left: 10px;
    color: #333333;
  }
}
.product-top {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-img {
    width: 700px;
    // margin-right: 30px;
    // height: ;
  }
}
.product-title {
  font-size: 28px;
  font-weight: 600;
  //   margin-bottom: 10px;
  color: #333333;
}
.product-title2 {
  font-size: 16px;
  margin-bottom: 30px;
  color: #999999;
  span {
    margin: 0 10px;
  }
}

.product-line {
  height: 80px;
  text-align: center;
  line-height: 80px;
  width: 100%;
  font-size: 20px;
  color: #000000;
  margin-bottom: 30px;
  margin-top: 30px;
  background-image: linear-gradient(
    90deg,
    #ffffff 0%,
    #e6edff 50%,
    #ffffff 100%
  );
}
.detail-content {
  color: #666666;
  font-size: 16px;
  line-height: 30px;
}
.news-detail-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
  span {
    cursor: pointer;
  }
  .right {
    color: #1d5eed;
  }
}
</style>
