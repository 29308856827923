<template>
  <div>

    <Head :navId="2"></Head>
    <aside id="fh5co-hero">
      <div class="flexslider">
        <ul class="slides">
          <li :style="'background-image: url('+(bannerSwitch?banner:banner1)+');'"></li>
        </ul>
      </div>
    </aside>

    <div class="container product-nav">
      <span @click="pagesTo('/home')" style="cursor: pointer;">首页</span>
      <span>>></span>
      <span @click="pagesTo('/Product')" style="cursor: pointer;">产品展示</span>
      <span>>></span>
      <span style="color: #1D5EED;">{{detail.title}}</span>
    </div>

    <div class="container">
      <div class="product-top">
        <img class="product-top-img" :src="detail.image_input" alt />
        <div class="product-right">
          <div class="product-right-title">{{detail.title}}</div>
          <div class="product-right-content">{{detail.synopsis}}</div>
          <div class="product-right-btn">
            <el-button @click="showKf" style="background-color: #1D5EED;" type="primary" icon="el-icon-chat-dot-square">在线咨询</el-button>
            <el-button style="background-color: #1D5EED;" type="primary" icon="el-icon-phone-outline">{{phone}}</el-button>
            <!-- <el-button type="primary" :icon="Search">Search</el-button> -->
          </div>
        </div>
      </div>
    </div>

    <div class="product-line">产品详情</div>

    <div class="container">
      <div class="detail-content">
        <div v-html="detail.content"></div>
      </div>
    </div>

    <div class="row animate-box">
      <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
        <h2>{{ title }}</h2>
        <p>{{ title_e }}</p>
      </div>
    </div>
    <img class="nav-img" :src="navImg" alt />

    <!-- </div> -->

    <div id="fh5co-course">
      <div class="container">
        <div class="container-item" @click="toDetail(item)" v-for="(item,i) in content" :key="i">
          <div style="overflow: hidden;">
            <img :src="item.image_input" />
          </div>
          <div>{{ item.title }}</div>
        </div>
      </div>
    </div>
    <Footer ref="footer"></Footer>
  </div>
</template>
<script>
import { Head, Footer } from "../components/unit/index";
import { productDetail, productHot } from "../api/index";
import { getBanner } from "@/api/home";

// import { Delete, Edit, Search, Share, Upload } from '@element-plus/icons-vue'
export default {
  components: {
    Head,
    Footer,
    // Delete, Edit, Search, Share, Upload
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
  },
  data() {
    return {
      schemeList: [],
      navIndex: 0,
      detail: {},
      title: "",
      typeId: '',
      therapy: require("../static/product/therapy1.png"),
      navImg: require("../static/index/nav-i.png"),
      navImg1: require("../static/index/nav-i1.png"),
      content: [
        {
          img: require("../static/product/therapy1.png"),
          title: "心身一体诊疗系统",
        },
        {
          img: require("../static/product/therapy2.png"),
          title: "儿童智力评估与训练系统",
        },
        {
          img: require("../static/product/therapy3.png"),
          title: "孕产妇身心健康管理系统",
        },
        {
          img: require("../static/product/therapy4.png"),
          title: "计算机神经功能康复评定系统",
        },
        {
          img: require("../static/product/therapy5.png"),
          title: "团体型音乐心理治疗系统",
        },
      ],
      title_e: '',
      banner: '',
      banner1: ""
    };
  },
  computed: {
    bannerSwitch() {
      return this.$store.state.bannerSwitch
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.title = to.query.title;
    this.typeId = to.query.typeId;
    next()
    this.onShow();
  },
  computed: {
    phone() {
      let obj = this.$store.state.setting.find((item) => {
        return item.info == "联系电话";
      });
      return obj ? obj.value : ''
    },
    bannerSwitch() {
      return this.$store.state.bannerSwitch
    }
  },
  mounted() {
    // this.title = this.$route.query.title;
    this.typeId = this.$route.query.typeId;
    this.onShow();
  },
  methods: {
    onShow() {
      getBanner(2).then((res) => {
        this.banner = res.data[0].img_path;
        this.banner1 = res.data[0].mobile_img_path;
      });
      productDetail({ id: this.$route.query.id }).then((res) => {
        this.detail = res.data;
        this.title = res.data.type_name;
        this.title_e = res.data.type_name_lang;

        let pagekeywords = document.querySelector('meta[name="keywords"]');
        pagekeywords.setAttribute("content", this.detail.keyword);

        let pagetitle = document.querySelector('meta[name="title"]');
        pagetitle.setAttribute("content", this.detail.title);

        let description = document.querySelector('meta[name="description"]');
        description.setAttribute("content", this.detail.seo_title);
        document.getElementsByTagName("title")[0].innerText = this.detail.title

      });
      this.getProductHot();
    },
    getProductHot() {
      let data = {
        type_id: this.typeId,
        page_index: 1,
        page_size: 6,
      };
      productHot(data).then((res) => {
        if (res.status == 200) {
          this.content = res.data;
        }
      });
    },
    showKf() {
      this.$refs.footer.showDetail();
    },
    pagesTo(page){
      this.$router.push({
        path: page,
      });
    },
    toDetail(item) {
      this.$router.push({
        path: "/productDet",
        query: {
          id: item.id,
          title: this.title,
          typeId: this.typeId,
        },
      });
    },
    getWorks() {
      let params = {
        cates: 25,
      };
      getWorksListApi(params).then((res) => {
        if (res.code == 200) {
          let data = res.data;
          let arr = [];
          data.forEach(function (item) {
            let img = item.image;
            // let val = img
            arr.push(img);
          });
          this.schemeList = arr;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  //line-height: 200px;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .product-top {
    flex-direction: column;
  }
  .product-top-img {
    margin-right: 0 !important;
    width: 100% !important;
  }
  .animate-box {
    justify-content: center;
  }
  #fh5co-course {
    padding-top: 0;
  }
  .product-nav {
    margin-bottom: 10px !important;
    margin-top: 20px !important;
  }
}
.el-carousel__item:nth-child(2n) {
  //background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  //background-color: #d3dce6;
}
.check-more {
  display: block;
  width: 80px;
  height: 25px;
  border: 2px solid #999;
  text-align: center;
  line-height: 25px;
  position: absolute;
  bottom: 50px;
}
.word-p {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.course-img {
  img {
    width: 100%;
    height: 100%;
  }
}

.img_Soft {
  width: 40%;
  height: 400px;
  img {
    width: 100%;
    height: 100%;
  }
}
.container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  .container-item {
    width: 30%;
    // margin-bottom: 20px;
    margin: 0 1.666% 30px;
    cursor: pointer;
    box-shadow: 0px 0px 10px 1px rgba(29, 51, 100, 0.1);
    img {
      width: 100%;
    }
    div {
      text-align: center;
      padding: 10px 0;
      color: #333333;
      font-size: 12px;
      font-size: 100%;
    }
  }
}

.row {
  display: flex;
}

::v-deep .my-gallery {
  //deep深层作用选择器
  display: flex;
  height: 390px;
  figure {
    width: 30%;
    margin: 5px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (min-width: 390px) and (max-width: 969px) {
  ::v-deep .my-gallery {
    //deep深层作用选择器
    display: flex;
    flex-direction: column;
    height: 100%;
    figure {
      width: 100%;
      margin: 5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

::v-deep .pswp__button--share {
  display: none !important;
}
.product-nav {
  margin-bottom: 20px;
  span {
    margin-left: 10px;
    color: #333333;
  }
}
.product-top {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  &-img {
    width: 500px;
    margin-right: 30px;
    // height: ;
  }
}
.product-right {
  &-title {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 20px;
    color: #333333;
  }
  &-content {
    color: #666666;
    line-height: 36px;
    letter-spacing: 2px;
  }
  &-btn {
    margin-top: 80px;
  }
}

.product-line {
  height: 80px;
  text-align: center;
  line-height: 80px;
  width: 100%;
  font-size: 20px;
  color: #000000;
  margin-bottom: 30px;
  margin-top: 30px;
  background-image: linear-gradient(
    90deg,
    #ffffff 0%,
    #e6edff 50%,
    #ffffff 100%
  );
}
.detail-content {
  color: #666666;
  font-size: 16px;
  line-height: 30px;
}
</style>
