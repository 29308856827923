<template>
  <div id="Head">
    <el-button class="js-fh5co-nav-toggle fh5co-nav-toggle fh5co-nav-white" @click="drawer = true" type="primary" style="margin-left: 25px ;background:transparent">
      <i></i>
    </el-button>
    <el-drawer title="我是标题" :visible.sync="drawer" :direction="direction" :with-header="false" :show-close="true" :append-to-body="true">
      <div id="fh5co-offcanvas">
        <ul>
          <li :class="Cindex == index ? 'active' : ''" v-for="(item, index) in menudata" :key="item.id" @click="highlight(index)">
            <router-link v-if="!item.type" :to="{
                name: item.cate_folder,
                params: { id: index },
              }">{{ item.cate_name }}</router-link>
            <a style="cursor: pointer;" @click="toPages(item)" v-else>{{ item.cate_name }}</a>
          </li>
        </ul>
      </div>
    </el-drawer>
    <nav class="fh5co-nav" role="navigation">
      <div class="top-menu">
        <div class="container">
          <div class="row" style="display: flex;align-items: center;width: 90%;justify-content: space-between;">
            <div class="col-xs-2">
              <!--logo修改-->
              <div id="fh5co-logo">
                <a href="index.html">
                  <img :src="logoSrc" />
                </a>
              </div>
            </div>
            <div class="show-search col-xs-10 text-right menu-1" v-show="searchType&&!showSearch">
              <ul>
                <li :class="Cindex == index ? 'active' : ''" v-for="(item, index) in menudata" :key="item.id">
                  <router-link @click="highlight(index)" v-if="!item.type" :to="{
                        name: item.cate_folder,
                        params: { id: index },
                      }">{{ item.cate_name }}</router-link>
                  <a style="cursor: pointer;" @click="toPages(item)" v-else>{{ item.cate_name }}</a>
                </li>
              </ul>
            </div>

            <div class="search-div" v-show="!searchType||showSearch">
              <el-popover placement="bottom" trigger="manual" v-model="searchPo">
                <div class="history-div" style="padding:20px;width: 43.5vw">
                  <div class="search-title">
                    <span>
                      最近搜索
                    </span>
                    <img @click="delSearch" src="../../static/images/search-del.png" alt="">
                  </div>
                  <div class="search-content">
                    <span @click="clickSearch(item)" v-for="(item,i) in searchs" :key="i">{{item}}</span>
                  </div>
                </div>

                <div slot="reference" class="search-div2" style="width: 30vw;">
                  <el-input @blur="searchPo=false" @focus="searchPo=true" @keyup.enter.native="inputChange" style="width: 38vw;" ref="input" class="head-input" placeholder="请输入关键字" v-model="search"></el-input>
                  <img v-show="search" src="../../static/images/clear.png" class="search-clear" @click="clearSearch">
                  <img @click="inputChange" class="search-i" src="../../static/images/search.png" alt="">
                </div>
              </el-popover>

              <img @click="closeSearch" class="search-i2" src="../../static/images/head-del.png" alt="">
            </div>

            <!-- v-if="showSearch" -->
            <!-- @blur="drawer1 = false" -->
            <img v-show="searchType&&!showSearch" @click="tosearch" class="search-i" src="../../static/images/search.png" alt="">
          </div>
        </div>
      </div>
    </nav>
    <!-- :append-to-body="true" -->

    <!-- <el-drawer :before-close="handleClose" @close="drawer1=false" style="margin-top: 72px;" :with-header="false" :visible.sync="drawer1" custom-class="drawer-class" direction="ttb">
      <div v-if="content&&content.length" class="container">
        <div class="container-item" @click="toDetail(item)" v-for="(item,i) in content" :key="i">
          <div class="container-item-div" style="overflow: hidden;">
            <img :src="item.image_input" />
          </div>
          <div>{{ item.title }}</div>
        </div>
      </div>
      <el-empty :image-size="300" v-else description="暂无搜索结果" />
    </el-drawer> -->
  </div>
</template>
<script>
import { menuApi } from "@/api/index";
export default {
  name: "Head",
  props: {
    msg: String,
    navId: {
      type: Number,
      default: 0,
    },
    // search: {
    //   type: Boolean,
    //   default: true,
    // },
    showSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Cindex: 0,
      logoSrc: require("../../static/images/logo.png"),
      menudata: [],
      drawer: false,
      drawer1: false,
      direction: "rtl",
      codeSwitch: false,
      search: "",
      content: [],
      searchType: true,
      searchs: [],
      searchPo: false,
    };
  },
  watch: {
    navId() {
      this.search = "";
    },
  },
  mounted() {
    let a = window.localStorage.getItem("search");
    if (a) {
      this.searchs = a.split(",");
    }
    this.Cindex = this.navId;
    this.getMeau();
    var that = this;
    if (
      Object.prototype.toString.call(this.$route.params.id) !=
      "[object Undefined]"
    ) {
      this.Cindex = this.$route.params.id;
    }
  },
  methods: {
    clearSearch() {
      this.search = "";
      this.$refs.input.focus()
    },
    tosearch() {
      // console.log(123)
      // this.searchPo = true
      this.$router.push({ path: "/search" });
    },
    delSearch() {
      this.searchPo = true;
      this.$msgbox.confirm("确认删除搜索记录", "提示", {
        // if you want to disable its autofocus
        // autofocus: false,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        callback: (action) => {
          this.searchs = [];
          this.searchPo = false;
          window.localStorage.setItem("search", "");
          // this.$message({
          //   type: "info",
          //   message: `已删除`,
          // });
        },
      });
    },
    closeSearch() {
      this.drawer1 = false;
      // this.searchType = !this.searchType;
      this.$router.push({ path: "/home" });
    },
    clickSearch(i) {
      this.search = i;
      this.searchPo = false;
      this.inputChange();
    },
    handleClose() {
      this.drawer1 = false;
      return false;
    },
    clickDrawer() {
      this.$refs.input.blur();
    },
    toDetail(item) {
      let path = "";
      if (item.mode == 2) {
        path = "/productDet";
      } else if (item.mode == 1) {
        path = "/Newsdetails";
      } else if (item.mode == 3) {
        path = "/CaseDet";
      }
      this.drawer1 = false;
      return this.$router.push({
        path: path,
        query: { id: item.id, title: item.title, typeId: item.cid },
      });
    },
    inputChange(e) {
      if (!this.search) return;
      this.searchPo = false;
      // this.$emit("searchChange",e);
      let a = window.localStorage.getItem("search");
      let c;
      if (a) {
        c = a.split(",");
      } else {
        c = [];
      }

      if (c.length) {
        for (let i = 0; i < c.length; i++) {
          if (c[i] == this.search) {
            break;
          }
          if (i == c.length - 1) {
            c.push(this.search);
          }
        }
      } else {
        c.push(this.search);
      }

      window.localStorage.setItem("search", c);
      this.searchs = c;

      if (this.search) {
        this.$emit("inputChange", this.search);
      } else {
        this.content = [];
      }
    },
    toPages() {
      let arr = this.$store.state.setting;
      // console.log(arr,'arrr')

      // arr = JSON.parse(arr);
      let obj = arr.find((item) => {
        return item.menu_name == "taobao_url";
      });
      // obj.value = obj.value.replace(/\\/g, "");
      // console.log(obj.value, "arr");
      var win = window.open(obj.value, "_blank");
      win.focus();
      return;
    },
    //获取菜单
    highlight(index) {
      this.Cindex = index;
      // this.Cindex = this.$route.params.id;
    },
    //点击事件
    listClick(val) {
      if (val.pid == 4) {
        //跳转daodao
        const cate_name = val.cate_name;
        this.$router.push({ path: "/Skill", query: { cate_name: cate_name } });
      } else if (val.pid == 8) {
        const id = val.id;
        this.$router.push({ path: "/SolutionDetails", query: { id: id } });
      } else if (val.pid == 1) {
        const id = val.id;
        this.$router.push({ path: "/Software", query: { id: id } });
      } else {
        const id = val.id;
        const cate_folder = "/" + val.cate_folder;
        this.$router.push({ path: cate_folder, query: { id: id } });
      }
    },
    getMeau() {
      this.menudata = [
        { cate_name: "首页", cate_folder: "home" },
        { cate_name: "关于我们", cate_folder: "About" },
        { cate_name: "产品展示", cate_folder: "Product" },
        { cate_name: "案例展示", cate_folder: "Case" },
        { cate_name: "新闻资讯", cate_folder: "Skill" },
        { cate_name: "联系我们", cate_folder: "Contact" },
        {
          cate_name: "淘宝旗舰店",
          type: "tobao",
          cate_folder: "https://www.baidu.com/",
        },
      ];
      this.$store.commit("updateData", this.menudata);

      // return;

      if (this.$store.state.menuList.length > 0) {
        this.menudata = this.$store.state.menuList;
      } else {
        menuApi().then((res) => {
          if (res.code == 200) {
            console.log(res.data, "res.data");
            // return;
            // this.menudata = res.data;
            // this.menudata.unshift({ cate_name: "首页", cate_folder: "home" });
            // this.$store.commit("updateData", this.menudata);
          }
        });
      }
    },
    //首页进行跳转
    jumpPage() {
      this.$router.push({ path: "/home" });
    },
    btn() {
      this.codeSwitch = !this.codeSwitch;
    },
  },
};
</script>
<style lang="less" scoped>
.homePage {
  color: rgba(0, 0, 0, 0.9);
  font-size: 14px;
  margin-right: 15px !important;
  cursor: pointer;
}
.fh5co-nav {
  width: 100%;
  padding: 0;
  z-index: 8999;
  position: relative;
}
.fh5co-nav .top-menu {
  padding: 0;
}
.fh5co-nav .top {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 0px 0;
  margin-bottom: 0;
}
.fh5co-nav .top .num,
.fh5co-nav .top .fh5co-social,
.fh5co-nav .top .site {
  display: inline-block;
  margin: 0;
  padding: 5px 12px;
}
@media screen and (max-width: 768px) {
  .fh5co-nav .top .num,
  .fh5co-nav .top .fh5co-social,
  .fh5co-nav .top .site {
    padding: 5px 10px;
  }
}
.fh5co-nav .top .site {
  float: left;
  font-weight: 300;
  margin-top: 0px;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}
@media screen and (max-width: 480px) {
  .fh5co-nav .top .site {
    display: none;
  }
}
.fh5co-nav .top .num {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  letter-spacing: 0px;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  border-right: 1px solid rgba(0, 0, 0, 0.08); /*2017-12-6*/
}
.fh5co-nav .top .fh5co-social {
  margin: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}
.fh5co-nav .top .fh5co-social li {
  font-size: 14px;
  /* display: block; */
  /* display: inline-block; */
}
.fh5co-nav .top .fh5co-social li a {
  padding: 7px 7px;
}
.fh5co-nav .top .fh5co-social li a i {
  font-size: 14px;
}
.fh5co-nav #fh5co-logo {
  font-size: 24px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-weight: 700;
  font-family: "Source Sans Pro", Arial, sans-serif;
}
.fh5co-nav #fh5co-logo a {
  color: #000;
  position: relative;
  padding-left: 25px;
}
/*2017-11-30*/
.fh5co-nav #fh5co-logo img {
  height: 30px;
}
.fh5co-nav #fh5co-logo a span {
  color: #000;
}
.fh5co-nav #fh5co-logo a i {
  position: absolute;
  top: 5px;
  left: 0;
}
.search-i2 {
    width: 24px;
    height: 24px;
    margin-left: 15px;
    background-color: #ffffff;
    cursor: pointer;
  }
@media screen and (max-width: 768px) {
  .fh5co-nav .menu-1 {
    display: none;
  }
  .search-i {
    display: none !important;
  }
  .search-div {
    display: none !important;
  }
}
.fh5co-nav ul {
  padding: 0;
  margin: 0;
  display: flex;
}
.fh5co-nav ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  /* display: inline; */
  /* display: block; */
  display: flex;
  font-weight: 300;
}
// .fh5co-nav ul li a {
//   font-size: 14px;
//   /* padding: 20px 26px; */
//   color: rgba(0, 0, 0, 0.9);
//   border-radius: 2px;
//   -webkit-transition: 0.5s;
//   -o-transition: 0.5s;
//   transition: 0.5s;
// }
/* .fh5co-nav ul li a:hover,
.fh5co-nav ul li a:focus, */
.fh5co-nav ul li a:active {
  color: #ffffff;
  background-color: #1d5eed;
}
.fh5co-nav ul li.has-dropdown {
  position: relative;
}
.fh5co-nav ul li.has-dropdown .dropdown {
  width: 140px;
  -webkit-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  z-index: 1002;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 40px;
  left: 0;
  text-align: left;
  background: #000;
  padding: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}
.fh5co-nav ul li.has-dropdown .dropdown:before {
  bottom: 100%;
  left: 40px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #000;
  border-width: 8px;
  margin-left: -8px;
}
.fh5co-nav ul li.has-dropdown .dropdown li {
  display: block;
  margin-bottom: 7px;
}
.fh5co-nav ul li.has-dropdown .dropdown li:last-child {
  margin-bottom: 0;
}
.fh5co-nav ul li.has-dropdown .dropdown li a {
  padding: 2px 0;
  display: block;
  color: #999999;
  line-height: 1.2;
  text-transform: none;
  font-size: 13px;
  letter-spacing: 0;
}
.fh5co-nav ul li.has-dropdown .dropdown li a:hover {
  color: #fff;
}
.fh5co-nav ul li.has-dropdown:hover a,
.fh5co-nav ul li.has-dropdown:focus a {
  color: #000;
}
.fh5co-nav ul li.btn-cta a {
  padding: 30px 0px !important;
  color: #fff;
}
.fh5co-nav ul li.btn-cta a span {
  background: #2d6cdf;
  padding: 4px 10px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-border-radius: 1;
  -moz-border-radius: 1;
  -ms-border-radius: 1;
  border-radius: 1;
}
.fh5co-nav ul li.btn-cta a:hover span {
  -webkit-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
}
.fh5co-nav ul li.active > a {
  font-weight: 400;
  background-color: #1d5eed;
  color: #ffffff;
}
.fh5co-nav-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-decoration: none;
}
.fh5co-nav-toggle.active i::before,
.fh5co-nav-toggle.active i::after {
  background: #444;
}
.fh5co-nav-toggle:hover,
.fh5co-nav-toggle:focus,
.fh5co-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}
.fh5co-nav-toggle i {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 2px;
  color: #252525;
  font: bold 14px/0.4 Helvetica;
  text-transform: uppercase;
  text-indent: -6px;
  background: #252525;
  transition: all 0.2s ease-out;
}
.fh5co-nav-toggle i::before,
.fh5co-nav-toggle i::after {
  content: "";
  width: 25px;
  height: 2px;
  background: #252525;
  position: absolute;
  left: 0;
  transition: all 0.2s ease-out;
}
.fh5co-nav-toggle.fh5co-nav-white > i {
  color: #fff;
  background: #000;
}
.fh5co-nav-toggle.fh5co-nav-white > i::before,
.fh5co-nav-toggle.fh5co-nav-white > i::after {
  background: #000;
}
.fh5co-nav-toggle.fh5co-nav-white.active > i {
  color: #fff;
  background: #000;
}
.fh5co-nav-toggle.fh5co-nav-white.active > i::before,
.fh5co-nav-toggle.fh5co-nav-white.active > i::after {
  background: #fff;
}

.fh5co-nav-toggle i::before {
  top: -7px;
}

.fh5co-nav-toggle i::after {
  bottom: -7px;
}

.fh5co-nav-toggle:hover i::before {
  top: -10px;
}

.fh5co-nav-toggle:hover i::after {
  bottom: -10px;
}

.fh5co-nav-toggle.active i {
  background: transparent;
}

.fh5co-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.fh5co-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.fh5co-nav-toggle {
  position: absolute;
  right: 10px;
  top: -6px;
  z-index: 99999 !important;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  display: none;
  height: 44px;
  width: 44px;
  z-index: 2001;
  background: transparent;
  border: none !important;
}
@media screen and (max-width: 768px) {
  .fh5co-nav-toggle {
    display: block;
  }
}

.codeimg {
  width: 150px;
  height: 150px;
  position: absolute;
  left: 85%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-top: 12px;
  z-index: 9999;
}

.codeimg::before {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  width: 0;
  height: 0;
  border-top: none;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #fff;
  border-left: 5px solid transparent;
  border-style: 1px solid #ebeef5;
}

.img {
  width: 150px;
  height: 150px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  // margin-top: 60px;
  .container-item {
    width: 30%;
    // margin-bottom: 20px;
    margin: 40px 1% 30px;
    cursor: pointer;
    &-div {
      height: 276px;
      display: flex;
      align-items: center;
      img {
        width: 100%;
      }
    }

    div {
      text-align: center;
      padding: 10px 0;
      color: #333333;
      font-size: 16px;
    }
  }
}

.search-clear {
  width: 31px;
  height: 16px;
  padding-right: 15px;
  cursor: pointer;
  border-right: 1px solid #E6E6E6;
}

.search-i {
  margin-left: 30px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
@media screen and (max-width: 1600px) {
  .search-div {
    .search-div2 {
      width: 45vw !important;
    }
    // .search-content {
    //   width: 33vw !important;
    // }
    .history-div {
      width: 45vw !important;
    }
    &/deep/.el-input__inner {
      width: 33vw !important;
    }
  }
}
@media screen and (min-width: 1600px) and (max-width: 2400px) {
  .search-div2 {
    width: 35vw !important;
  }
  .history-div {
    width: 34vw !important;
  }
}
@media screen and (min-width: 2400px) {
  .search-div2 {
    width: 25vw !important;
  }
  .history-div {
    width: 24vw !important;
  }
}
.search-div {
  display: flex;
  align-items: center;
  margin: 20px 50px 20px 0;
  &/deep/.el-input__inner {
    border: 0;
    font-size: 18px;
    box-shadow: 0 0 0 0px;
  }

  .search-div2 {
    padding: 5px 20px 5px 5px;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    display: flex;
    position: relative;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
  }
  .search-i {
    margin-left: 15px;
    background-color: #ffffff;
  }
}
.search-title {
  color: #333333;
  margin-bottom: 20px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.search-content {
  display: flex;
  & > span {
    margin-right: 20px;
    cursor: pointer;
    background-color: #f2f2f2;
    color: #333333;
    padding: 6px 20px;
    border-radius: 50px;
  }
}
</style>
<style>
.drawer-class {
  height: 100vh !important;
}
</style>
