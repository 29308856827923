<template>

  <div class="indexStyle">
    <el-carousel height="100vh" class="index-carousel" @change="carousel" :interval="8000">
      <el-carousel-item style="opacity: 0;" :class="num==i?'carousel-style':''" v-for="(item,i) in content" :key="item.id">
        <div class="index-div">
          <img :class="num==i?'img-style':''" class="index-img" :src="bannerSwitch?item.image_path:item.mobile_image_path" alt="">
        </div>
      </el-carousel-item>
    </el-carousel>

    <div class="index-btn">
      <div @click="toHome" style="background: #1D5EED;color: #ffffff;" class="btns">进入主页</div>
      <div @click="toNews" class="btns">相关新闻</div>
    </div>
  </div>

</template>
<script>
import { getBanner, pageImage } from "@/api/home";
export default {
  name: "Head",
  props: {
    msg: String,
    input1: "",
    navId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      content: {},
      num: 0
    };
  },
  computed: {
    bannerSwitch(){
      return this.$store.state.bannerSwitch
    }
  },
  mounted() {
    pageImage().then((res) => {
      this.content = res.data;
      // console.log(res.data[0], "resss");
    });
  },
  methods: {
    carousel(e) {
      this.num = e
    },
    toNews() {
      this.$router.push({
        path: "/Skill"
      });
    },
    toHome() {
      this.$router.push({
        path: "/Home"
      });
    },
    //获取菜单
    highlight(index) {
      this.Cindex = index;
      // this.Cindex = this.$route.params.id;
    },
  },
};
</script>
<style scoped>
.index-div {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
@media screen and (min-width: 1200px) {
  .index-img {
    height: fit-content;
    width: 100%;
  }
  .index-btn {
    left: 20.5vw;
    top: 69vh;
  }
}

@media screen and (max-width: 1200px) {
  .index-img {
    /* height: 100%; */
    width: 100% !important;
  }
  .index-btn {
    left: 16vw;
    top: 69vh;
  }
}
@media screen and (max-width: 800px) {
  .index-img {
    /* margin-left: 90vw; */
  }
  .index-btn {
    left: 10vw;
    top: 60vh;
  }
  /* .index-carousel {
    width: 300vw;
  }
  .indexStyle {
    width: 300vw;
  } */
}

.index-btn {
  display: flex;
  position: absolute;
  z-index: 999;
}
.index-btn .btns {
  margin-right: 20px;
  text-align: center;
  padding: 6px 20px 4px;
  background-color: #ffffff;
  cursor: pointer;
}

.carousel-style {
  opacity: 1 !important;
  transition: opacity 2000ms ease 0s !important;
}
.index-img {
  transition: all 2000ms ease 0s;
  transform: scale(1.1);
}
.img-style{
  transform: scale(1);

}
.el-carousel__item.is-animating {
  /* transition: ""; */
}
</style>
