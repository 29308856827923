import Vue from 'vue'
import Vuex from 'vuex'
import { getSeo } from "@/api/home";

Vue.use(Vuex)

let handle = (data) => {
    let keyWord = data.find(item => item.menu_name === 'web_keyword').value
    let title = data.find(item => item.menu_name === 'web_title').value
    let content = data.find(item => item.menu_name === 'web_content').value

    let pagekeywords = document.querySelector('meta[name="keywords"]');
    pagekeywords.setAttribute("content", keyWord);

    let pagetitle = document.querySelector('meta[name="title"]');
    pagetitle.setAttribute("content", title);

    let description = document.querySelector('meta[name="description"]');
    description.setAttribute("content", content);
    document.getElementsByTagName("title")[0].innerText = title
}
const store = new Vuex.Store({
    state: {
        menuList: [],
        link: [],
        qrCode: [],
        setting: [],
        seo: [],
        bannerSwitch: false
    },
    mutations: {
        updateData(state, data) {
            state.menuList = data
        },
        setSeo(state) {
            if (state.seo.length) {
                handle(state.seo)
            } else {
                getSeo().then(res => {
                    let data = res.data
                    state.seo = data
                    handle(state.seo)

                })
            }
        },
        shift(state, data) {
            let handleResize = () => {
                // 获取视口宽度和高度
                const viewportWidth = Math.max(
                    document.documentElement.clientWidth,
                    window.innerWidth || 0
                );
                if (viewportWidth <= 600) {
                    state.bannerSwitch = false
                } else {
                    state.bannerSwitch = true
                }
            }
            // state.menuList = data
            window.addEventListener("resize", handleResize);
            handleResize()

        },
    },
    actions: {},
    modules: {}
})
export default store