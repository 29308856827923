<template>
  <div style="overflow-x: hidden;">

    <Head></Head>
    <el-carousel height="550px" :interval="8000" v-if="bannerSwitch" @change="carousel1">
      <el-carousel-item v-for="(item,i) in bannerdata" :key="item.index" style="opacity: 0;" :class="num1==i?'carousel-style':''">
        <div class="baaner_mb">
          <img :class="num1==i?'img-style':''" class="home-headImg" :src="item.img" />
        </div>
      </el-carousel-item>
    </el-carousel>
    <el-carousel @change="carousel2" height="50vw" :interval="8000" v-else>
      <el-carousel-item v-for="(item,i) in bannerdata" :key="item.index" style="opacity: 0;" :class="num2==i?'carousel-style':''">
        <!-- @click.native="bannerLink(item)" -->
        <div class="baaner_mb">
          <img :class="num2==i?'img-style':''" class="home-headImg" :src="item.mobile_image||item.img" />
          <!-- <span class="imgText">{{ item.text }}</span> -->
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="home-introduce row">
      <div class="home-introduce-div" @click="toPage(item)" v-for="(item,i) in introduce" :key="i">
        <img class="home-introduce-img" :src="item.img" />
        <div style="width:150px">
          <div>{{item.title}}</div>
          <div style="margin-bottom:20px;">{{item.enTitle}}</div>
          <img :src="right_i" class="introduce-i" />
        </div>
      </div>
    </div>
    <div class="center-banner">
      <img :src="banner1" class="index-banner" />
      <img :src="banner2" class="index-banner" />
    </div>

    <!-- <div class="copyrights">
      Collect from
      <a href="http://www.cssmoban.com/" title="网站模板">网站模板</a>
    </div> -->
    <!--    element-轮播图-->
    <div class="fh5co-course has-mobile" v-if="nav[0]">
      <div class="container">
        <div class="row animate-box">
          <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
            <h2>{{ nav[0].title }}</h2>
            <p>{{nav[0].title_lang}}</p>
          </div>
        </div>
        <img class="nav-img" :src="navImg" alt />
        <div style="width:100%;">
          <el-carousel height="348px" indicator-position="none">
            <el-carousel-item v-for="(item,i) in list1" :key="i">
              <div @click="detail(item,nav[0])" style="display: flex; flex-direction: column;width: 100%;height: fit-content;margin: 0 auto">
                <div style="width: 100%; height: 300px" :style="'background: url('+item.image_input+') no-repeat center / contain'"></div>
                <p style="text-align: center; font-size: 18px; color: #333333;">{{item.title}}</p>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <div class="fh5co-course has-pc" v-if="nav[0]">
      <div class="container">
        <div class="row animate-box">
          <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
            <h2>{{ nav[0].title }}</h2>
            <p>{{nav[0].title_lang}}</p>
          </div>
        </div>
        <img class="nav-img" :src="navImg" alt />
        <div style="width:100%;">
          <div id="homeShow" class="home-show row">
            <div @click="detail(item,nav[0])" v-for="(item,i) in list1" :key="i" class="animate-box">
              <div class="course">
                <div class="course-img" :style="'background-image: url('+item.image_input+')'"></div>
                <div class="desc">
                  <h3>
                    <div>{{item.title}}</div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fh5co-course has-mobile" v-if="nav[1]">
      <div class="container">
        <div class="row animate-box">
          <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
            <h2>{{ nav[1].title }}</h2>
            <p>{{nav[1].title_lang}}</p>
          </div>
        </div>
        <img class="nav-img" :src="navImg" alt />
        <div style="width:100%;" lca>
          <el-carousel height="348px" indicator-position="none">
            <el-carousel-item v-for="(item,i) in list2" :key="i">
              <div @click="detail(item,nav[1])" style="display: flex; flex-direction: column;width: 100%;height: fit-content;margin: 0 auto">
                <div style="width: 100%; height: 300px" :style="'background: url('+item.image_input+') no-repeat center / contain'"></div>
                <p style="text-align: center; font-size: 18px; color: #333333;">{{item.title}}</p>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="fh5co-course has-pc" v-if="nav[1]">
      <div class="container">
        <div class="row animate-box">
          <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
            <h2>{{ nav[1].title }}</h2>
            <p>{{nav[1].title_lang}}</p>
            <!-- <p>Training and treatment products</p> -->
          </div>
        </div>
        <img class="nav-img" :src="navImg" alt />
        <!-- <div class="row"> -->
        <div style="width:100%;">
          <div id="homeShow1" class="home-show row">
            <div v-for="(item,i) in list2" :key="i" @click="detail(item,nav[1])" class="animate-box">
              <div class="course">
                <div class="course-img" :style="'background-image: url('+item.image_input+')'"></div>
                <div class="desc">
                  <h3>
                    <div>{{item.title}}</div>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fh5co-course bottom-img" :style="'background-image: url('+bottomImg+');'">
      <div class="container">
        <div class="row animate-box">
          <div class="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <h2>五大优势-精神心理专业设备的研发</h2>
            <p>FIVE ADVANTAGES</p>
          </div>
        </div>
        <img class="nav-img" :src="navImg" alt />

        <div class="row home-view">
          <div class="col-lg-3 col-md-3">
            <div class="fh5co-blog animate-box">
              <a href="javascript:void(0);" class="blog-img-holder" :style="'background-image: url('+imgage_1+')'"></a>
              <div class="blog-text">
                <h3>
                  <a href="javascript:void(0);">15年研发经验</a>
                </h3>
                <img class="nav-img1" :src="navImg1" alt />

                <span class="posted_on">精神科、心理科</span>
                <!--<span class="comment"><a href="">21<i class="icon-speech-bubble"></i></a></span>-->
                <p>15年精神科设备研发经验</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
            <div class="fh5co-blog animate-box">
              <a href="javascript:void(0);" class="blog-img-holder" :style="'background-image: url('+imgage_2+')'"></a>
              <div class="blog-text">
                <h3>
                  <a href="javascript:void(0);">研发设计</a>
                </h3>
                <img class="nav-img1" :src="navImg1" alt />

                <span class="posted_on">10余人研发设计团队</span>
                <!--<span class="comment"><a href="">21<i class="icon-speech-bubble"></i></a></span>-->
                <p>产品在精神科领域占有率</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
            <div class="fh5co-blog animate-box">
              <a href="javascript:void(0);" class="blog-img-holder" :style="'background-image: url('+imgage_3+')'"></a>
              <div class="blog-text">
                <h3>
                  <a href="javascript:void(0);">专业化</a>
                </h3>
                <img class="nav-img1" :src="navImg1" alt />

                <span class="posted_on">任务标准化并结合多媒体技术</span>
                <!--<span class="comment"><a href="">21<i class="icon-speech-bubble"></i></a></span>-->
                <p>治疗过程患者依从性高</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
            <div class="fh5co-blog animate-box">
              <a href="javascript:void(0);" class="blog-img-holder" :style="'background-image: url('+imgage_4+')'"></a>
              <div class="blog-text">
                <h3>
                  <a href="javascript:void(0);">品质保障</a>
                </h3>
                <img class="nav-img1" :src="navImg1" alt />

                <span class="posted_on">多年市场经验验证</span>
                <!--<span class="comment"><a href="">21<i class="icon-speech-bubble"></i></a></span>-->
                <p>自主研发、生产、编程质量可控</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3">
            <div class="fh5co-blog animate-box">
              <a href="javascript:void(0);" class="blog-img-holder" :style="'background-image: url('+imgage_5+')'"></a>
              <div class="blog-text">
                <h3>
                  <a href="javascript:void(0);">无忧售后机制</a>
                </h3>
                <img class="nav-img1" :src="navImg1" alt />

                <span class="posted_on">提供现场安装培训指导</span>
                <!--<span class="comment"><a href="">21<i class="icon-speech-bubble"></i></a></span>-->
                <p>提供终身远程技术协助</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="test"></div>

    <div class="fh5co-course">
      <div class="container">
        <div class="row animate-box">
          <div class="col-md-6 col-md-offset-3 text-center fh5co-heading">
            <h2>新闻资讯</h2>
            <p>NEWS INFORMATION</p>
          </div>
        </div>
        <img class="nav-img" :src="navImg" alt />
        <div style="overflow: hidden;width:100%;">
          <div class="home-show1 row">
            <div class="animate-box" @click="clicknews(item)" v-for="(item,i) in list3" :key="i">
              <div class="course bottom-item" style="align-items: baseline;">
                <div class="course-img bottom1" :style="'background-image: url('+item.image_input+')'"></div>
                <div class="title">
                  <div class="name">{{ item.title }}</div>
                  <div class="content">{{ item.synopsis }}</div>
                </div>
              </div>
            </div>
          </div>

          <!--<div class="col-md-4 animate-box">
            <div class="course bottom-item">
              <a
                href="javascript:void(0);"
                class="course-img bottom1"
                :style="'background-image: url('+bottom1+')'"
              ></a>
              <div class="title">
                <div class="name">心身一体诊疗系统</div>
                <div class="content">第一章：脑电生物反馈概述。脑电生物反馈是一种通过监测和调节个体脑电活动来增强自我调节能力的...</div>
              </div>
            </div>
          </div>
           <div class="col-md-4 animate-box">
            <div class="course bottom-item">
              <a
                href="javascript:void(0);"
                class="course-img bottom1"
                :style="'background-image: url('+bottom2+')'"
              ></a>
              <div class="title">
                <div class="name">韦氏智力评估的准确性和可靠性</div>
                <div class="content">韦氏智力评估，也被称为斯坦福-韦斯特曼量表，是一种常用于测量个体智力水平的工具。它旨在评估...</div>
              </div>
            </div>
          </div>
          <div class="col-md-4 animate-box">
            <div class="course bottom-item">
              <a
                href="javascript:void(0);"
                class="course-img bottom1"
                :style="'background-image: url('+bottom3+')'"
              ></a>
              <div class="title">
                <div class="name">认知行为治疗系统的应用案例</div>
                <div class="content">认知行为治疗系统（CBT）是一种广泛应用于心理健康领域的心理治疗方法。它通过帮助个体意识到他...</div>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>

    <div class="index-bottom" @click="toMoreNews">
      <span>查看更多</span>
      <img :src="item_i" alt />
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { Head, Footer } from "../components/unit/index";
// import { getSeo } from "@/api/home";
import countTo from "vue-count-to";
import {
  getproductList,
  getImageListApi,
  productList,
  getNewsHot,
} from "@/api/index";
export default {
  components: {
    Head,
    Footer,
    countTo,
  },
  data() {
    return {
      //数字开始
      startVal: 0,
      //数字结束
      endVal: 5000,
      newDate: new Date(),
      timer: null,
      year: 0,
      month: 0,
      day: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      item_i: require("../static/images/news-i.png"),
      nav: [],
      bottom1: require("../static/index/bottom1.png"),
      bottom2: require("../static/index/bottom2.png"),
      bottom3: require("../static/index/bottom3.png"),
      imgSrc: require("../static/images/img_bg_4.jpg"),
      // imgSrc: require("../static/images/img_bg_4.jpg"),

      imgSrc_1: require("../static/index/item1.png"),
      imgSrc_2: require("../static/index/item2.png"),
      imgSrc_3: require("../static/index/item3.png"),
      imgSrc_4: require("../static/index/item4.png"),
      imgSrc_5: require("../static/index/item5.png"),
      imgSrc_6: require("../static/index/item6.png"),

      imgage_1: require("../static/index/image1.png"),
      imgage_2: require("../static/index/image2.png"),
      imgage_3: require("../static/index/image3.png"),
      imgage_4: require("../static/index/image4.png"),
      imgage_5: require("../static/index/image5.png"),

      // imgSrc_7: require("../static/images/project_6.png"),
      // imgSrc_8: require("../static/images/newsBanner.png"),
      // imgSrc_9: require("../static/images/img_bg_2.jpg"),
      banner1: require("../static/index/banner.png"),
      banner2: require("../static/index/banner1.png"),

      navImg: require("../static/index/nav-i.png"),
      navImg1: require("../static/index/nav-i1.png"),
      bottomImg: require("../static/index/index-bottom.png"),

      introduce: [
        {
          title: "走进我们",
          page: "About",
          enTitle: "WALK INTO US",
          img: require("../static/index/index1.png"),
        },
        {
          title: "产品中心",
          page: "Product",

          enTitle: "PRODUCT CENTER",
          img: require("../static/index/index2.png"),
        },
        {
          title: "新闻中心",
          page: "Skill",

          enTitle: "NEWS CENTER",
          img: require("../static/index/index3.png"),
        },
        {
          title: "联系我们",
          enTitle: "CONTACT US",
          page: "Contact",
          img: require("../static/index/index4.png"),
        },
      ],
      right_i: require("../static/index/index-right.png"),
      bannerdata: [],
      bannerappSwitch: false,
      list1: [],
      list2: [],
      list3: [],
      num1: 0,
      num2: 0,
    };
  },
  computed: {
    bannerSwitch() {
      return this.$store.state.bannerSwitch
    }
  },
  created() {
    this.getImageList();
    this.getList();
    // let pageTitle = document.querySelector('meta[name="keywords"]');
    // pageTitle.setAttribute("content", "javascript修改meta标签示例");
    // console.log(pageTitle,'pageTitle')
    // document.getElementsByTagName("title")[0].innerText='需要设置的值'
  },
  mounted() {
    this.$store.commit('setSeo')
    // var that = this; // 声明一个变量指向Vue实例this，保证作用域一致
    // //显示当前日期时间
    // this.timer = setInterval(() => {
    //   that.newDate = new Date(); // 修改数据date
    //   that.dateFormat(that.newDate);
    //   that.getDataTime();
    // }, 1000);
    // this.changeAmount();

    // window.addEventListener("resize", this.handleResize);
    // this.handleResize();
  },
  // beforeDestroy() {
  //   if (this.timer) {
  //     clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
  //   }
  // },
  methods: {
    carousel1(e) {
      console.log(e, 'n1')
      this.num1 = e;
    },
    carousel2(e) {
      console.log(e, 'eee')
      this.num2 = e;
    },
    clicknews(item) {
      const id = item.id;
      this.$router.push({
        path: "/Newsdetails",
        query: { id: id },
      });
    },
    getList() {
      getproductList().then((nav) => {
        this.nav = nav.data;

        let data = {
          type_id: this.nav[0].id,
          page_index: 1,
          page_size: 6,
        };
        productList(data).then((res) => {
          if (res.status == 200) {
            let data = res.data.data

            this.list1 = [...data, ...data];
          }
        });
        data.type_id = this.nav[1].id;
        productList(data).then((res) => {
          if (res.status == 200) {
            let data = res.data.data
            this.list2 = [...data, ...data];
            // this.toRoll()
          }
        });
        data.type_id = "";
        data.page_size = 3;
        getNewsHot(data).then((res) => {
          if (res.status == 200) {
            this.list3 = res.data;
          }
        });
      });
    },
    // toRoll(){
    //   setTimeout(() => {
    //       // let div=$()
    //       $("#homeShow").each(function (index, dom) {
    //         //将每个 的所有子级都复制一遍
    //         var rows = $(dom).children().clone();
    //         //再将新的到的加入原来的
    //         $(dom).append(rows);
    //       });
    //
    //       $("#homeShow1").each(function (index, dom) {
    //         //将每个 的所有子级都复制一遍
    //         var rows = $(dom).children().clone();
    //         //再将新的到的加入原来的
    //         $(dom).append(rows);
    //       });
    //       // $('#homeShow3').each(function (index, dom) {
    //       //   //将每个 的所有子级都复制一遍
    //       //   var rows = $(dom).children().clone();
    //       //   //再将新的到的加入原来的
    //       //   $(dom).append(rows);
    //       // })
    //     }, 1000);
    // },
    toMoreNews() {
      this.$router.push({
        path: "/Skill",
      });
    },
    detail(item, nav) {
      const id = item.id;
      // const activeName = this.activeName;
      this.$router.push({
        path: "/productDet",
        query: { id: id, title: nav.title, typeId: nav.id },
      });
    },
    toPage(item) {
      this.$router.push(item.page);
    },
    //获取当前时间
    dateFormat(time) {
      let date = new Date(time);
      let year = date.getFullYear();
      let wk = date.getDay();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let weeks = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let week = weeks[wk];
      // this.year = year;
      // this.month = month;
      // this.day = day;
      // this.hours = hours;
      // this.minutes = minutes;
      // this.seconds = seconds;
    },
    getImageList() {
      // this.bannerdata = [
      //   { img: this.imgSrc }
      // ];
      // return;
      let params = {
        // type_id: 1
      };

      getImageListApi(params).then((res) => {
        if (res.status == 200) {
          let imageList = [];
          res.data.forEach((item, index) => {
            imageList.push({
              img: item.image,
              mobile_image: item.mobile_image,
            });
          });
          // this.bannerdata
          this.bannerdata = imageList;
        }
      });
    },
    getDataTime() {
      // 定义开始日期和结束日期
      const startDate = new Date("2020-04-07 00:00:00");
      const endDate = new Date();
      // 计算时间差（毫秒）
      const timeDiff = endDate - startDate;
      // 转换为年、月、日、时、分、秒
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
      this.day = days;
      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;
    },
    Software() {
      this.$router.push("/Software");
    },
    bannerLink(val) {
      window.location.href = val.url;
    },
    // handleResize() {
    //   // 获取视口宽度和高度
    //   const viewportWidth = Math.max(
    //     document.documentElement.clientWidth,
    //     window.innerWidth || 0
    //   );
    //   console.log(viewportWidth,'viewportWidth')
    //   if (viewportWidth <= 600) {
    //     (this.bannerappSwitch = true), (this.bannerSwitch = false);
    //   }else {
    //     (this.bannerappSwitch = false), (this.bannerSwitch = true);
    //   }
    // },
  },
};
</script>
<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.el-carousel__container {
  position: relative;
}
.baaner_mb {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  // background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-carousel__item h1 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 40px;
  text-align: center;
  font-weight: 300;
}

// .el-carousel__item:nth-child(2n) {
//   background-color: #99a9bf;
//   background: url(../static/images/img_bg_2.jpg) no-repeat bottom center;
//   background-size: cover;
// }

// .el-carousel__item:nth-child(2n + 1) {
//   background-color: #d3dce6;
//   background: url(../static/images/img_bg_1.jpg) no-repeat bottom center;
//   background-size: cover;
// }
@media (min-width: 1200px) {
  .col-lg-3 {
    width: 20%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1300px;
  }
}
.blog-text a {
  background-color: #ffffff;
}
.imgText {
  position: absolute;
  top: 65%;
  left: 4.2%;
  color: #fff;
  font-size: 25px;
  width: 500px;
  height: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

@media screen and (max-width: 1060px) {
  .imgText {
    width: 200px;
  }
  .home-introduce-div {
    width: 45% !important;
    padding: 20px 0 20px 10px !important;
  }
  .home-introduce-img {
    margin-right: 10px !important;
    width: 13vw !important;
    height: 13vw !important;
  }
}
@media screen and (max-width: 960px) {
  .home-show1 {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    width: 100% !important;
    justify-content: center;
  }
  .fh5co-course {
    padding-bottom: 0 !important;
  }
}
.home-introduce {
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0;
  justify-content: space-evenly;
}
.home-introduce-div {
  width: 260px;
  display: flex;
  cursor: pointer;
  margin: 10px 0;
  align-items: flex-start;
  /* align-items: center; */
  padding: 20px 0 20px 20px;
  transition: all 0.2s ease;
}
.home-introduce-div:hover {
  box-shadow: 0px 0px 10px 1px rgba(29, 51, 100, 0.1);
}

.home-introduce-img {
  margin-right: 20px;
  width: 20%;
  height: min-content;
}
.introduce-i {
  float: right;
  width: 20px;
}
.index-banner {
  width: 100%;
}

.bottom-img {
  margin-top: 0 !important;
  padding-top: 30px;
  background-size: 100% 100%;
}
.nav-img1 {
  width: 30px;
  margin: 10px auto;
  display: block;
}
.course .bottom1 {
  height: 240px !important;
}
.title {
  padding: 10px;
  // text-align: center;
  // margin-top: 20px;
}
.title .name {
  margin: 20px 0 0px;
  color: #000000;
  font-size: 18px;
}
.title .content {
  margin: 0px 0 10px;
  font-size: 14px;

  height: 72px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
// box-shadow: inset -70px 0px 77px 11px rgba(0, 0, 0, 0.2);
.bottom-item:hover {
  box-shadow: 0px 0px 10px 1px rgba(29, 51, 50, 0.1);
}
.index-bottom {
  display: flex;
  justify-content: center;
  color: #1d5eed;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 80px;
  margin-top: 40px;
}
.index-bottom img {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}
.animate-box {
  cursor: pointer;
}
.home-show {
  display: flex;
  width: max-content;
  & > div {
    // flex: none;
    width: 400px;
  }
}
.home-show1 {
  display: flex;
  & > div {
    flex: none;
  }
}
#homeShow1,
#homeShow {
  /* //infinite永久调用动画 */
  animation: row 60s linear infinite;
}

/*鼠标划入 停止动画  */
#homeShow1:hover,
#homeShow:hover {
  // animation-play-state: paused;
}

@keyframes row {
  0% {
  }

  100% {
    transform: translateX(-50%);
  }
}
.carousel-style {
  opacity: 1 !important;
  transition: opacity 2000ms ease 0s !important;
}
.index-img {
}
.img-style {
  transform: scale(1) !important;
  // opacity: 1 !important;
}
.el-carousel__item.is-animating {
  /* transition: ""; */
}

.home-headImg {
  height: 100%;
  transition: all 2000ms ease 0s;
  transform: scale(1.1);
}
.home-show1 > div,
.home-show > div {
  margin: 0 15px 30px;
}
.has-mobile {
  display: none;
}
.has-pc {
  display: block;
}
@media screen and (max-width: 768px) {
  .has-mobile {
    display: block;
  }
  .has-pc {
    display: none;
  }
}

.img-list-size {
  width: 100%;
  height: fit-content;
  background: pink;
}
.img-list-size::after {
  content: "";
  display: block;
  pointer-events: none;
  width: 100%;
  padding-top: 62.4%;
}
</style>
