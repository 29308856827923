<template>
  <div>
    <Head :navId="4"></Head>
    <aside id="fh5co-hero">
      <div class="flexslider">
        <ul class="slides">
          <li
            :style="
              'background-image: url(' +
              (bannerSwitch ? banner : banner1) +
              ');'
            "
          ></li>
        </ul>
      </div>
    </aside>

    <div class="product-nav">
      <div
        v-for="(item, i) in nav"
        @click="getProduct(item.id, true)"
        :key="i"
        :class="navIndex == item.id ? 'bavStyle' : ''"
        class="product-nav-div"
      >
        {{ item.title }}
      </div>
      <!-- <div @click="navIndex=1" :class="navIndex==1?'bavStyle':''" class="product-nav-div">训练治疗类产品</div> -->
    </div>

    <div id="fh5co-pricing" class="fh5co-bg-section">
      <div class="container">
        <div class="row">
          <div class="pricing pricing--rabten">
            <div
              class="animate-box news-div"
              v-for="(item, index) in content"
              :key="index"
            >
              <div class="card-div" @click="clicknews(item)">
                <div class="img-div">
                  <img :src="item.image_input" alt />
                </div>
                <div class="text-div">
                  <h4 class="card-name">{{ item.title }}</h4>
                  <div class="hour-div">{{ item.date_str }}</div>

                  <div class="word-d">{{ item.synopsis }}</div>
                  <div class="radius-div">
                    查看详情
                    <img :src="item_i" alt />
                  </div>
                </div>
              </div>
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              @current-change="currentPageClick"
              :current-page="currentPage"
              style="width: 100%; text-align: center;"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { Head, Footer } from '../components/unit/index'
import { getNewsList, getNewsCate } from '../api/index'
import { getBanner } from '@/api/home'

// import { getListApi, trpeListApi, getHotListApi } from "@/api/index";
export default {
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      imgSrc: require('../static/images/img_bg_gywm.jpg'),
      imgSrc_1: require('../static/images/fengmian.jpeg'),
      item_i: require('../static/images/news-i.png'),
      bannerdata: {},
      navIndex: 0,
      newList: [
        // {
        //   title: "123好",
        //   summary:
        //     "第一章：脑电生物反馈概述。脑电生物反馈是一种通过监测和调节个体脑电活动来增强自我调节能力的方法。它结合了神经科学、心理学和技术工具，旨在帮助个体通过可视化和听觉反馈来掌握自我控制技巧，从而增强身体和心理的...",
        //   time: "2024-06-14",
        //   image: require("../static/images/fengmian.jpeg")
        // }
      ],
      newClassify: [],
      activeName: '全部',
      classifyList: [],
      curTabName: '',
      total: 0,
      pageSize: 6,
      cate_id: '',
      jumpName: '',
      nav: [],
      navIndex: 0,
      content: [],
      total: 1,
      currentPage: 1,
      banner: '',
      banner1: '',
    }
  },
  computed: {
    bannerSwitch() {
      return this.$store.state.bannerSwitch
    },
  },
  mounted() {
    this.$store.commit('setSeo')
    this.navIndex = this.$route.query.id || 0
    getBanner(4).then((res) => {
      this.banner = res.data[0].img_path
      this.banner1 = res.data[0].mobile_img_path
    })
    this.getWorks()
  },
  watch: {},
  methods: {
    getProduct(id, type = false) {
      if (type) {
        this.currentPage = 1
      }
      let data = {
        type_id: id,
        page_index: this.currentPage,
        page_size: 10,
      }
      getNewsList(data).then((res) => {
        if (res.status == 200) {
          this.navIndex = id
          this.content = res.data.data
          this.total = res.data.total
        }
      })
    },
    getWorks() {
      let params = {
        // cates: 25
      }
      getNewsCate(params).then((res) => {
        if (res.status == 200) {
          let data = res.data
          this.nav = data
          this.getProduct(this.nav[this.navIndex].id)
        }
      })
    },
    //热门新闻接口
    getHotListMethod() {
      const data = {
        cates: '5,6',
        limit: this.pageSize,
        page: this.currentPage,
      }
      getHotListApi(data).then((res) => {
        if (res.code == 200) {
          this.newList = res.data.data
          this.total = this.newList.length
          this.newList.forEach((item) => {
            let img = item.image
            if (img.includes('https://')) {
              item.image = img
            } else {
              item.image = this.$BaseUrl + item.image
            }
            item.date = item.create_time.split(' ')[0]
          })
        }
      })
    },
    //新闻列表接口
    getListMethod() {
      const data = {
        cate_id: this.cate_id,
        limit: this.pageSize,
        page: this.currentPage,
      }
      getListApi(data).then((res) => {
        if (res.code == 200) {
          this.total = res.data.total
          this.classifyList = res.data.data
          this.classifyList.forEach((item, index) => {
            let img = item.image
            if (img.includes('https://')) {
              item.image = img
            } else {
              item.image = this.$BaseUrl + item.image
            }
            item.date = item.create_time.split(' ')[0]
          })
        }
        // console.log(res,"这是新闻列表接口");
      })
    },

    // async getNewList() {
    //   let res = await getListApi();
    //   if (res.code == 200) {
    //     this.newList = res.data.data ? res.data.data : [];
    //     this.newList.forEach((item) => {
    //       item.image = "https://www2.njzhidian.com" + item.image;
    //       item.date = item.create_time.split(" ")[0];
    //       item.time = item.create_time.split(" ")[1];
    //     });
    //   }
    //   if (this.activeName) {
    //     this.classifyList = this.newList.filter(
    //       (item) => item.cate_name == this.activeName
    //     );
    //   }else{
    //       this.activeName="全部"
    //       this.classifyList = this.newList //默认展示全部
    //   }
    //     console.log("this.newList", this.newList);
    //     console.log("this.classifyList",this.classifyList);
    // },

    //新闻分类事件
    getTrpeList() {
      trpeListApi().then((res) => {
        if (res.code == 200) {
          this.newClassify = res.data
        }
      })
    },

    //新闻点击事件
    clicknews(item) {
      let obj = this.nav.find((e) => {
        return e.id == this.navIndex
      })
      this.$router.push({
        path: '/Newsdetails',
        query: { id: item.id, title: obj.title, typeId: obj.id },
      })
    },

    //标签页点击事件
    handleClick(tab, event) {
      console.log('handleClick', tab.name)
      if (tab.name == '企业新闻') {
        this.cate_id = 5
        this.currentPage = 1
        this.getListMethod()
      } else if (tab.name == '行业资讯') {
        this.cate_id = 6
        this.currentPage = 1
        this.getListMethod()
      } else if (tab.name == '全部') {
        this.currentPage = 1
        this.getHotListMethod()
      }
      this.curTabName = tab.name
    },
    currentPageClick(val) {
      this.currentPage = val
      this.getProduct(this.navIndex)
    },
  },
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 768px) {
  .card-div {
    flex-direction: column;
    margin: 10px 10px 0 !important;
  }
  .product-nav {
    width: 300px !important;
  }
  .product-nav-div {
    width: 150px !important;
    font-size: 18px !important;
  }
  .card-div .img-div {
    width: 100% !important;
    img {
      width: 100% !important;
    }
  }
  .text-div {
    // font-size: 20px !important;
    padding-bottom: 40px !important;
  }
  .radius-div {
    font-size: 16px !important;
    right: 20px;
  }
  .is-background {
    margin-top: 20px;
  }
}
.product-nav {
  display: flex;
  width: 499px;
  border: 1px solid #becced;
  margin: 60px auto 0;
  &-div {
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #000000;
    cursor: pointer;
  }
  .bavStyle {
    color: #ffffff;
    background-image: linear-gradient(180deg, #4e85ff 0%, #1d5eed 100%);
  }
}
.tab-class {
  width: 100%;
  ::v-deep .el-tabs__active-bar {
    width: 383px !important;
  }
  ::v-deep .el-tabs__item {
    width: 383px !important;
    text-align: center;
  }
}
.iconfont {
  font-size: 20px;
}

.card-div {
  display: flex;
  position: relative;
  // border: 1px solid rgb(222, 222, 222);
  margin: 0px 15px 20px 0px;
  cursor: pointer;
  .img-div {
    width: 376px;
    height: 222px;
    overflow: hidden;
    flex: none;
    img {
      display: block;
      width: 376px;
      transform: scale(1);
      height: 222px;
      transition: all 0.2s ease-in-out;
    }
  }
}
.news-div:hover {
  box-shadow: 0px 0px 10px 1px rgba(29, 51, 100, 0.1);
}
.news-div:hover img {
  transform: scale(1.1);
  /* box-shadow: inset -70px 0px 77px 11px rgba(0, 0, 0, 0.2); */
}
.text-div {
  font-size: 16px;
  color: #000000;
  // line-height: 58px;
  padding: 10px;
  h4 {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 24px;
  }
  .word-d {
    line-height: 30px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-align: left;
    color: #666666;
  }
}
.text-div:hover {
  h4 {
    color: #1d5eed;
  }
}
.hour-div {
  color: #999999 !important;
}
.radius-div {
  position: absolute;
  cursor: pointer;
  color: #1d5eed;
  text-align: center;
  position: absolute;
  bottom: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  img {
    margin-left: 6px;
    width: 25px;
    height: 24px;
  }
}
.time-div {
  width: 376px;
  height: 100px;
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1d5eed !important;
}
/* Common styles */
</style>
